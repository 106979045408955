import { Dialog, Divider, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import useResponsive from '../../hooks/useResponsive';
import { StyledCardContent } from '../containers/StyledCardContent';
import { CloseIconButton } from '../dialog/CloseIconButton';
import { CenteredRow } from '../containers/CenteredRow';
import Iconify from '../iconify';

export interface FullscreenModalProps extends PropsWithChildren {
    titleText?: string | null;
    subtitleText?: string | null;
    boldSubtitle?: boolean;
    showDialog: boolean;
    onClose: VoidFunction;
    icon?: string;
    id?: string
}

export function FullscreenModal({ children, titleText = null, subtitleText = null, boldSubtitle = false, showDialog, onClose, icon, id }: FullscreenModalProps) {
    const fullscreenMode = useResponsive('down', 'sm');

    return (
        <Dialog
            fullScreen={fullscreenMode}
            open={showDialog}
            maxWidth='sm'
            fullWidth
            onClose={onClose}
            id={id}
        >
            <CloseIconButton onClose={onClose} />
            <StyledCardContent>
                <Stack spacing={1}>
                    <CenteredRow>
                        {icon && <Iconify icon={icon} width={24} mr={0.5} />}
                        <Typography variant='h6'>{titleText || ''}</Typography>
                    </CenteredRow>
                    {subtitleText &&
                        <>
                            <Divider />
                            <CenteredRow>
                                <Typography variant={boldSubtitle ? 'h6' : 'body1'}>{subtitleText}</Typography>
                            </CenteredRow>
                        </>
                    }
                    {children}
                </Stack>
            </StyledCardContent>
        </Dialog>
    );
}
